import React from "react";
import Calendar from "../../assets/CALENDARIO.svg"
import Location from "../../assets/UBICACIÓN.svg"
//import NoDates from "../../assets/proximos-shows.png"

import "./Tour.css"

const Tour = () => {

    // cuando hay fechas
    let dates = [
        {
            id: 1,
            name: "Sucio y Desprolijo",
            date: "Sábado 22 de Febrero, 22 hs",
            place: "Hawaii Bar, Mar del Plata"
        }
    ]

    return (
        <section id="tour" >
            <div className="Tour-container">
                <div className="Tour-text-container">
                <h1 className="Tour-title">TOUR</h1>
                <h2 className="Tour-subtitle">PRÓXIMAS FECHAS</h2>
                </div>

                <div className="Tour-dates">
                    <div className="Dates-container">
                        {dates.map((data, index) => {
                            return (
                                <div key={index} className="Tour-date">
                                    <span className="Tour-syd">{data.name}</span>
                                    <div className="Tour-calendar"><img className="Tour-svg" src={Calendar} /><span className="Dates-tour-data">{data.date}</span></div>
                                    <div className="Tour-location"><img className="Tour-svg" src={Location} /><span className="Dates-tour-data">{data.place}</span></div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )

    // cuando no hay fechas
    /*return (
        <section id="tour" >
            <div className="Tour-container">
                <div className="Tour-text-container">
                <h1 className="Tour-title">TOUR</h1>
                </div>

                <div className="Tour-none">
                    <img className="No-tour-img" src={NoDates} alt=""/>
                </div>
            </div>
        </section>
    )*/
}

export default Tour;
